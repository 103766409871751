import axios from '@axios'
import qs from 'qs'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        search(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/serviceorder/search', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        view(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/serviceorder/view', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        edit(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/serviceorder/edit', {params: params})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        save(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/save', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        state(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/state', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        relationSalesOrder(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/relationSalesOrder', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        relationSalesOrderOff(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/relationSalesOrderOff', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        export(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/export', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        exportOff(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/exportOff', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        createReturnOrder(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/createReturnOrder', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        changeProductId(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/changeProductId', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        createSalesReturnOutbound(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/createSalesReturnOutbound', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        createSalesReturnOutboundOff(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/createSalesReturnOutboundOff', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        delete(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/delete', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        batchDelete(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/batchDelete', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        relationSalesOrderAndCreateSalesReturnOutbound(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/relationSalesOrderAndCreateSalesReturnOutbound', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        changeWarehouse(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/serviceorder/changeWarehouse', params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
