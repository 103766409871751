<template>
  <b-card
    class="serviceorder-edit-wrapper"
  >
    <!-- form -->
    <b-form id="serviceorderForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="渠道ID"
            label-for="company_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_id"
              size="sm"
              v-model="serviceorder.company_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="店铺ID"
            label-for="store_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_id"
              size="sm"
              v-model="serviceorder.store_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部订单ID"
            label-for="out_order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_order_id"
              size="sm"
              v-model="serviceorder.out_order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部售后订单编号"
            label-for="out_service_order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_service_order_id"
              size="sm"
              v-model="serviceorder.out_service_order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部产生售后日期  年/月/日 转化为Long"
            label-for="out_service_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_service_date"
              size="sm"
              v-model="serviceorder.out_service_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部售卖日期"
            label-for="out_sales_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_sales_time"
              size="sm"
              v-model="serviceorder.out_sales_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品id"
            label-for="out_product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_product_id"
              size="sm"
              v-model="serviceorder.out_product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品名称"
            label-for="out_product_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_product_name"
              size="sm"
              v-model="serviceorder.out_product_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品规格"
            label-for="out_product_rule"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_product_rule"
              size="sm"
              v-model="serviceorder.out_product_rule"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="售后原因"
            label-for="reason"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="reason"
              size="sm"
              v-model="serviceorder.reason"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="售后总金额（正数）"
            label-for="total_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="total_amount"
              size="sm"
              v-model="serviceorder.total_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="售后数量"
            label-for="total_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="total_qty"
              size="sm"
              v-model="serviceorder.total_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="来源 1数据导入  2平台接口"
            label-for="source"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="source"
              size="sm"
              v-model="serviceorder.source"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="我方系统商品id"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="serviceorder.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="我方系统商品名称"
            label-for="product_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_name"
              size="sm"
              v-model="serviceorder.product_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否已和销售订单关联"
            label-for="is_related"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_related"
              size="sm"
              v-model="serviceorder.is_related"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="sales_order.order_id"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="serviceorder.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="sales_order_item.item_id"
            label-for="item_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="item_id"
              size="sm"
              v-model="serviceorder.item_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出仓库ID 多个用逗号分隔"
            label-for="from_warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="from_warehouse_id"
              size="sm"
              v-model="serviceorder.from_warehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入仓库ID"
            label-for="to_warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="to_warehouse_id"
              size="sm"
              v-model="serviceorder.to_warehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否已创建退货申请单"
            label-for="is_createorder"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_createorder"
              size="sm"
              v-model="serviceorder.is_createorder"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="serviceorder.create_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人ID"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="serviceorder.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新时间"
            label-for="update_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="update_time"
              size="sm"
              v-model="serviceorder.update_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新人ID"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="serviceorder.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import serviceorderStore from './serviceorderStore'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      serviceorder: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('serviceorder')) store.registerModule('serviceorder', serviceorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('serviceorder')) store.unregisterModule('serviceorder')
    })

    const edit = function() {
      store.dispatch('serviceorder/edit', {id: this.id}).then(res => {
        this.serviceorder = res.data.data
      })
    }

    const view = function() {
      store.dispatch('serviceorder/view', {id: this.id}).then(res => {
        this.serviceorder = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('serviceorder/save', this.serviceorder).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
